<template>
    <div class="dropdown float-right mt-1">
      <b-dropdown
          no-caret
          right
          toggle-class="p-0 mr-1"
          variant="link"
      >
        <template #button-content>
          <feather-icon
              class="align-middle text-body"
              icon="MoreVerticalIcon"
              size="16"
          />
        </template>
        <b-dropdown-item>
          <feather-icon
              class="mr-50"
              icon="EditIcon"
              size="16"
          />
          <span class="font-weight-bolder"> Edit </span>
        </b-dropdown-item>
        <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'title-asc' } }">
          <feather-icon
              class="mr-50"
              icon="TrashIcon"
              size="16"
          />
          <span class="font-weight-bolder"> Delete </span>
        </b-dropdown-item>
      </b-dropdown>
    </div>

</template>

<script>
import {BDropdown, BDropdownItem} from 'bootstrap-vue'

export default {
  components: {
    BDropdown, BDropdownItem
  }
}
</script>
